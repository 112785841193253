export enum RSVPOptionType {
  YES = 'YES',
  NO = 'NO',
  MAYBE = 'MAYBE',
  ALL = 'ALL',

  // used for filter
  WAITLIST = 'WAITLIST',
  PENDING_APPROVAL = "PENDING_APPROVAL"
}

export namespace RSVPOptionType {
  export function getAllTypes(): RSVPOptionType[] {
    return [RSVPOptionType.YES, RSVPOptionType.NO, RSVPOptionType.MAYBE];
  }

  export function getDisplayName(relevance?: RSVPOptionType): string {
    switch (relevance) {
      case RSVPOptionType.YES:
        return 'APP.RSVP_OPTION.YES';
      case RSVPOptionType.NO:
        return 'APP.RSVP_OPTION.NO';
      case RSVPOptionType.MAYBE:
        return 'APP.RSVP_OPTION.MAYBE';
      case RSVPOptionType.WAITLIST:
        return 'APP.RSVP_OPTION.WAITLIST';
      case RSVPOptionType.PENDING_APPROVAL:
        return 'APP.RSVP_OPTION.PENDING_APPROVAL';
      default:
        return '';
    }
  }
}

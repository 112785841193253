import { environment as env } from '@environments/environment';
import { Brand } from '@models/brands/brand.model';
import { EventCampaignCategory } from '@models/campaigns/event-campaign-category.enum';
import { EventCampaignStatus } from '@models/campaigns/event-campaign-status.enum';
import { EventCampaign } from '@models/campaigns/event-campaign.model';
import { ClassData } from '@models/class-data';
import { EventTemplate } from '@models/design-templates/event-template.model';
import { EventAttendeeQuestion } from '@models/event-attendance/event-attendee-question.model';
import { EventAttendeeStatus } from '@models/event-attendance/event-attendee-status.enum';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import { EventRSVPConfirmationText } from '@models/event-attendance/event-rsvp-confirmation-text.model';
import { EventRSVPOption } from '@models/event-attendance/event-rsvp-option.model';
import { RSVPOptionType } from '@models/event-attendance/rsvp-option-type.enum';
import { EventCategory } from '@models/event-categories/event-category.model';
import { EventHostRoleEnum } from '@models/events/event-host-role.enum';
import { LandingPageEvent } from '@models/events/landing-page-event.model';
import { Address } from '@models/shared/address.model';
import { DateFormat } from '@models/shared/date-format.enum';
import { TimezoneOption } from '@models/timezone/timezone-option.model';
import { UserRole } from '@models/users/user-role.enum';
import { User } from '@models/users/user.model';
import { Helpers } from '@utils/helpers';
import moment, { Moment } from 'moment-timezone';
import { EventAccess } from './event-access.enum';
import { EventFrequency } from './event-frequency.enum';
import { EventHostStatus } from './event-host-status.enum';
import { EventHost } from './event-host.model';
import { EventPayment } from './event-payment.model';
import { EventStatus } from './event-status.enum';
import { EventTargetGroup } from './event-target-group.model';
import { EventTicket } from './event-ticket.model';
import { EventType } from './event-type.enum';

export class Event {
  id: number;
  createdAt?: Date;

  type: EventType;

  uuid: string;
  uri: string;

  categoryId: number;
  category?: EventCategory;

  eventTemplateId: number;
  eventTemplate?: EventTemplate;

  brandId?: number;
  brand?: Brand;

  hostUserId: number;
  hostUser?: User;
  // used by admin
  hostUserFull?: User;
  hostTeamId?: number;

  hosts?: EventHost[];

  eventAddressId: number;
  eventAddress?: Address;

  paymentId?: number;
  payment?: EventPayment;

  targetGroups?: EventTargetGroup[];
  attendees?: EventAttendee[];

  rsvpOptions?: EventRSVPOption[];
  rsvpConfirmationTexts?: EventRSVPConfirmationText[];

  status: EventStatus;
  access: EventAccess;

  activeFromDate?: Date;
  accessCode?: string;

  name: string;
  description: string;
  subHeader?: string;

  mainPictureUrl?: string;
  customPictureUrl?: string;
  tags: string;

  frequency: EventFrequency;

  ticketId?: number;
  ticket?: EventTicket;

  attendeeQuestions?: EventAttendeeQuestion[];

  eventCampaignId?: number;
  eventCampaign?: EventCampaign;

  startDate?: Date;
  endDate?: Date;
  timeZone: string;

  maxCapacity?: number;
  waitlistEnabled?: boolean;

  plusOnesLimit?: number;
  plusOnesAdditionalData: boolean;
  showPlusOnesFirstName: boolean;
  showPlusOnesLastName: boolean;
  showPlusOnesEmail: boolean;
  showPlusOnesPhone: boolean;

  showGuestList: boolean;
  showGuestCount: boolean;
  showFeedTimestamps: boolean;
  canAttendeesAddPhotos: boolean;
  canAddPhotosViaHashtag: boolean;
  canAttendeeInviteMutuals: boolean;

  rsvpEnabled: boolean;
  rsvpDisableDate?: Date;
  requireAttendeeApproval: boolean;
  requireGroupRsvp: boolean;

  sendEmailNotifications: boolean;
  sendSmsNotifications: boolean;
  sendWhatsAppNotifications: boolean;

  // used for template preview
  isPreviewEvent?: boolean;
  hiddenInLinkBio?: boolean;
  highlightedInLinkBio?: boolean;

  dateFormat?: DateFormat;

  landingPageEvents?: LandingPageEvent[];

  constructor(json: ClassData<Event>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.type = json.type;

    this.uuid = json.uuid;
    this.uri = json.uri;

    this.categoryId = +json.categoryId;
    if (json.category) {
      this.category = new EventCategory(json.category);
    }

    this.eventTemplateId = +json.eventTemplateId;
    if (json.eventTemplate) {
      this.eventTemplate = new EventTemplate(json.eventTemplate);
    }

    if (json.brandId !== undefined) {
      this.brandId = +json.brandId;
    }
    if (json.brand) {
      this.brand = new Brand(json.brand);
    }

    this.hostUserId = +json.hostUserId;
    if (json.hostUser) {
      this.hostUser = new User(json.hostUser);
    }
    if (json.hostUserFull) {
      this.hostUserFull = new User(json.hostUserFull);
    }
    if (json.hostTeamId !== undefined) {
      this.hostTeamId = +json.hostTeamId;
    }

    if (json.hosts) {
      this.hosts = this.mapEventHosts(json.hosts);
    }

    this.eventAddressId = +json.eventAddressId;
    if (json.eventAddress) {
      this.eventAddress = new Address(json.eventAddress);
    }

    if (json.paymentId !== undefined) {
      this.paymentId = +json.paymentId;
    }
    if (json.payment) {
      this.payment = new EventPayment(json.payment);
    }

    if (json.targetGroups) {
      this.targetGroups = json.targetGroups;
    }

    if (json.attendees) {
      this.attendees = this.mapEventAttendees(json.attendees);
    }

    if (json.rsvpOptions) {
      this.rsvpOptions = this.mapEventRSVPOptions(json.rsvpOptions);
    }

    if (!this.rsvpOptions || this.rsvpOptions.length === 0) {
      this.rsvpOptions = this.setDefaultRSVPOptions();
    }

    if (json.rsvpConfirmationTexts) {
      this.rsvpConfirmationTexts = this.mapRsvpConfirmationTexts(
        json.rsvpConfirmationTexts,
      );
    }

    this.status = json.status;
    this.access = json.access;

    if (json.activeFromDate) {
      this.activeFromDate = new Date(json.activeFromDate);
    }
    this.accessCode = json.accessCode;

    this.name = json.name;
    this.description = json.description;
    this.subHeader = json.subHeader;

    this.mainPictureUrl = json.mainPictureUrl;
    this.customPictureUrl = json.customPictureUrl;
    this.tags = json.tags;

    this.frequency = json.frequency;

    if (json.ticketId !== undefined) {
      this.ticketId = +json.ticketId;
    }
    if (json.ticket) {
      this.ticket = new EventTicket(json.ticket);
    }

    if (json.attendeeQuestions) {
      this.attendeeQuestions = this.mapAttendeeQuestions(
        json.attendeeQuestions,
      );
    }

    if (json.eventCampaignId !== undefined) {
      this.eventCampaignId = +json.eventCampaignId;
    }
    if (json.eventCampaign) {
      this.eventCampaign = new EventCampaign(json.eventCampaign);
    }

    if (json.startDate) {
      this.startDate = new Date(json.startDate);
    }
    if (json.endDate) {
      this.endDate = new Date(json.endDate);
    }
    this.timeZone = json.timeZone;

    if (json.maxCapacity !== undefined) {
      this.maxCapacity = +json.maxCapacity;
    }
    if (json.plusOnesLimit !== undefined) {
      this.plusOnesLimit = +json.plusOnesLimit;
    }
    this.plusOnesAdditionalData = json.plusOnesAdditionalData;
    this.showPlusOnesFirstName = json.showPlusOnesFirstName;
    this.showPlusOnesLastName = json.showPlusOnesLastName;
    this.showPlusOnesEmail = json.showPlusOnesEmail;
    this.showPlusOnesPhone = json.showPlusOnesPhone;

    this.showGuestList = json.showGuestList;
    this.showGuestCount = json.showGuestCount;
    this.showFeedTimestamps = json.showFeedTimestamps;
    this.canAttendeesAddPhotos = json.canAttendeesAddPhotos;
    this.canAddPhotosViaHashtag = json.canAddPhotosViaHashtag;
    this.canAttendeeInviteMutuals = json.canAttendeeInviteMutuals;
    this.waitlistEnabled = json.waitlistEnabled;

    this.rsvpEnabled = json.rsvpEnabled;
    if (json.rsvpDisableDate) {
      this.rsvpDisableDate = new Date(json.rsvpDisableDate);
    }
    this.requireAttendeeApproval = json.requireAttendeeApproval;
    this.requireGroupRsvp = json.requireGroupRsvp;

    this.sendEmailNotifications = json.sendEmailNotifications;
    this.sendSmsNotifications = json.sendSmsNotifications;
    this.sendWhatsAppNotifications = json.sendWhatsAppNotifications;

    this.isPreviewEvent = !!json.isPreviewEvent;
    this.hiddenInLinkBio = !!json.hiddenInLinkBio;
    this.highlightedInLinkBio = !!json.highlightedInLinkBio;

    this.dateFormat = json.dateFormat;

    if (json.landingPageEvents) {
      this.landingPageEvents = this.mapLandingPageEvents(
        json.landingPageEvents,
      );
    }
  }

  endsOnSameDate(): boolean {
    if (!this.endDate) {
      return false;
    }

    return moment(this.startDate).isSame(this.endDate, 'day');
  }

  hasAddress(): boolean {
    return !!this.eventAddress && this.eventAddress.isFilled();
  }

  hasLatLng(): boolean {
    return (
      !!this.eventAddress && !!this.eventAddress.lat && !!this.eventAddress.lng
    );
  }

  getSportPageLocationDisplay(): string | undefined {
    if (this.eventAddress) {
      let name = '';
      let street = '';
      let streetNumber = '';

      if (this.eventAddress?.name) {
        name = `(${this.eventAddress?.name})`;
      }

      if (this.eventAddress.street) {
        if (this.eventAddress.streetNumber) {
          streetNumber = this.eventAddress.streetNumber;
        }
        street = `${this.eventAddress.street} ${streetNumber},`;
      }
      return `${street} ${this.eventAddress?.locality} ${name}`;
    } else {
      return 'Die genaue Adresse der Location folgt';
    }
  }

  getStreet(): string {
    if (!this.eventAddress) {
      return '-';
    }

    return this.eventAddress.street;
  }
  getAddressDisplayName(): string {
    if (!this.eventAddress) {
      return '-';
    }
    return this.eventAddress.toStringWithName();
  }

  getGoogleMapsLink() {
    if (!this.eventAddress) {
      return '';
    }

    if (this.eventAddress.locality === 'Berlin') {
      return 'https://www.google.com/maps/place/Sportwiese+im+Friedrich-Ludwig-Jahn+Sportpark/@52.5463537,13.3981526,12z/data=!4m15!1m8!3m7!1s0x47a84e373f035901:0x42120465b5e3b70!2sBerlin,+Germany!3b1!8m2!3d52.5200066!4d13.404954!16zL20vMDE1NnE!3m5!1s0x47a853c767ae6bc3:0xac3675f2a5e53f3!8m2!3d52.5450201!4d13.4076325!16s%2Fg%2F11q8wm3rqr?entry=ttu';
    } else if (
      this.eventAddress.placeId &&
      this.eventAddress.placeId !== '' &&
      this.eventAddress.placeId.startsWith('Ch')
    ) {
      return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        '10437 Berlin, Germany',
      )}&query_place_id=${this.eventAddress.placeId}`;
    } else if (this.eventAddress.lat && this.eventAddress.lng) {
      return `https://www.google.com/maps/search/?api=1&query=${this.eventAddress.lat},${this.eventAddress.lng}`;
    } else {
      return '';
    }
  }

  getDisplayDate(): string {
    if (!this.startDate && !this.endDate) {
      return '-';
    }

    const startDate = moment(this.startDate).format('DD.MM.yyyy');
    const startTime = moment(this.startDate).format('HH:mm');
    const endTime = this.endDate ? moment(this.endDate).format('HH:mm') : '';

    let displayDate = `${startDate} ${startTime}`;

    if (this.endsOnSameDate()) {
      displayDate = `${displayDate} - ${endTime}`;
    } else if (this.endDate && !this.endsOnSameDate()) {
      const endDate = moment(this.endDate).format('DD.MM.yyyy');
      displayDate = `${displayDate} - ${endDate} ${endTime}`;
    }

    return displayDate;
  }

  getAttendees(type: RSVPOptionType): EventAttendee[] | undefined {
    if (!this.attendees || this.attendees.length === 0) return undefined;

    if (type === RSVPOptionType.YES) {
      return this.attendees.filter(
        (attendee) =>
          attendee.rsvpType === type &&
          attendee.status === EventAttendeeStatus.ATTENDING,
      );
    }

    return this.attendees.filter((attendee) => attendee.rsvpType === type);
  }

  getAttendeesByStatus(
    status: EventAttendeeStatus,
  ): EventAttendee[] | undefined {
    if (!this.attendees || this.attendees.length === 0) return undefined;

    return this.attendees.filter((attendee) => attendee.status === status);
  }

  getNumYesAttendees(): number {
    const yesAttendees = this.getAttendees(RSVPOptionType.YES);

    return yesAttendees ? yesAttendees.length : 0;
  }

  getUserAttendee(userId: number): EventAttendee | undefined {
    return this.attendees?.find((attendee) => attendee.userId === userId);
  }

  getUserAttendeeRSVPOption(userId: number): EventRSVPOption | undefined {
    const attendee = this.attendees?.find(
      (attendee) => attendee.userId === userId,
    );
    const attendeeType = attendee?.rsvpType;

    return this.rsvpOptions?.find((option) => option.type === attendeeType);
  }

  getAttendeeRSVPOptionById(id: number): EventRSVPOption | undefined {
    const attendee = this.attendees?.find((attendee) => attendee.id === id);
    const attendeeType = attendee?.rsvpType;

    return this.rsvpOptions?.find((option) => option.type === attendeeType);
  }

  getAttendeeRSVPOptionByEmail(email: string): EventRSVPOption | undefined {
    const attendee = this.attendees?.find(
      (attendee) =>
        attendee.getEmail() &&
        Helpers.normalizeGmail(attendee.getEmail().toLowerCase()) ===
          Helpers.normalizeGmail(email.toLowerCase()),
    );
    const attendeeType = attendee?.rsvpType;

    return this.rsvpOptions?.find((option) => option.type === attendeeType);
  }

  getUserRSVPTitle(userId: number): string {
    if (
      this.getUserAttendeeRSVPOption(userId)?.name.trim() === 'Jetzt Anmelden'
    ) {
      return 'APP.EVENTS.REGISTERED';
    }

    return this.getUserAttendeeRSVPOption(userId)?.name || '';
  }

  getUserRSVPIcon(userId: number): string {
    const attendee = this.attendees?.find(
      (attendee) => attendee.userId === userId,
    );
    const attendeeType = attendee?.rsvpType;

    if (attendeeType === RSVPOptionType.YES) {
      return 'fa-check';
    }
    if (
      attendeeType === RSVPOptionType.NO ||
      attendeeType === RSVPOptionType.MAYBE
    ) {
      return 'fa-question';
    }

    return '';
  }

  isUserHost(userId?: number): boolean {
    return (
      this.hostUserId === userId ||
      !!this.hosts?.find(
        (host) =>
          host.userId === userId && host.status === EventHostStatus.ACTIVE,
      )
    );
  }

  isNotLoggedUserHost(): boolean {
    return this.uuid === localStorage.getItem('eventUuid');
  }

  isAttendee(userId?: number): boolean {
    return !!this.attendees?.find((attendee) => attendee.userId === userId);
  }

  isNonPendingAttendee(userId?: number): boolean {
    return !!this.attendees?.find(
      (attendee) => attendee.userId === userId && attendee.isNonPending(),
    );
  }

  isNonPendingAttendeeById(id?: number): boolean {
    return !!this.attendees?.find(
      (attendee) => attendee.id === id && attendee.isNonPending(),
    );
  }

  isNonPendingAttendeeByEmail(email?: string): boolean {
    if (!email) {
      return false;
    }
    return !!this.attendees?.find(
      (attendee) =>
        attendee.getEmail() &&
        Helpers.normalizeGmail(attendee.getEmail().toLowerCase()) ===
          Helpers.normalizeGmail(email.toLowerCase()) &&
        attendee.isNonPending(),
    );
  }

  isNonPendingActiveAttendee(userId?: number): boolean {
    return !!this.attendees?.find(
      (attendee) => attendee.userId === userId && attendee.isNonPendingActive(),
    );
  }

  isPendingAttendee(userId?: number): boolean {
    return !!this.attendees?.find(
      (attendee) =>
        attendee.userId === userId &&
        attendee.status === EventAttendeeStatus.PENDING_APPROVAL,
    );
  }

  isPendingAttendeeById(id?: number): boolean {
    return !!this.attendees?.find(
      (attendee) =>
        attendee.id === id &&
        attendee.status === EventAttendeeStatus.PENDING_APPROVAL,
    );
  }

  isPendingAttendeeByEmail(email?: string): boolean {
    if (!email) {
      return false;
    }
    return !!this.attendees?.find(
      (attendee) =>
        attendee.getEmail() &&
        Helpers.normalizeGmail(attendee.getEmail().toLowerCase()) ===
          Helpers.normalizeGmail(email.toLowerCase()) &&
        attendee.status === EventAttendeeStatus.PENDING_APPROVAL,
    );
  }

  getAttendee(userId?: number): EventAttendee | undefined {
    return this.attendees?.find((attendee) => attendee.userId === userId);
  }

  getAttendeeById(id?: number): EventAttendee | undefined {
    return this.attendees?.find((attendee) => attendee.id === id);
  }

  getAttendeeByEmail(email?: string): EventAttendee | undefined {
    if (!email) {
      return undefined;
    }
    return this.attendees?.find(
      (attendee) =>
        attendee.getEmail() &&
        Helpers.normalizeGmail(attendee.getEmail().toLowerCase()) ===
          Helpers.normalizeGmail(email.toLowerCase()),
    );
  }

  getEventGoingRSVPTitle(): string {
    const rsvpYes = this.rsvpOptions?.find(
      (option) => option.type === RSVPOptionType.YES,
    );
    if (rsvpYes) {
      if (rsvpYes.name.trim() === 'Jetzt Anmelden') {
        return 'APP.EVENTS.REGISTERED';
      }

      return rsvpYes?.name || '';
    }

    return '';
  }

  getEventRSVPTitle(type: RSVPOptionType): string {
    return this.rsvpOptions?.find((option) => option.type === type)?.name || '';
  }

  getYesRSVPOption(): EventRSVPOption | undefined {
    return this.rsvpOptions?.find(
      (option) => option.type === RSVPOptionType.YES,
    );
  }

  getEventDateInfo(): string {
    if (!this.endDate) {
      return moment(this.startDate).format('Do [of] MMMM · ha');
    }

    const startDate = moment(this.startDate).format('Do [of] MMMM');
    const startTime = moment(this.startDate).format('ha');
    const endTime = moment(this.endDate).format('ha');

    if (this.endsOnSameDate()) {
      return `${startDate} · ${startTime} - ${endTime}`;
    }

    return `${moment(this.startDate).format('Do [of] MMMM · ha')} - ${moment(
      this.endDate,
    ).format('Do [of] MMMM · ha')}`;
  }

  getEventDateInfoForCard(): string {
    if (!this.endDate) {
      if (!this.startDate) {
        return 'TBD';
      }
      return moment(this.startDate).format('Do [of] MMMM · ha');
    }

    const startDate = moment(this.startDate).format('Do [of] MMMM');
    const startTime = moment(this.startDate).format('ha');
    const endTime = moment(this.endDate).format('ha');

    if (this.endsOnSameDate()) {
      return `${startDate} · ${startTime} - ${endTime}`;
    }

    return moment(this.startDate).format('Do [of] MMMM · ha');
  }

  isInTheFuture(): boolean {
    return (
      this.isPublished() &&
      (!this.startDate || this.startDate.getTime() > Date.now())
    );
  }

  isInThePast(): boolean {
    return (
      this.isPublished() &&
      !!this.startDate &&
      this.startDate.getTime() < Date.now()
    );
  }

  isPublished(): boolean {
    return (
      this.status === EventStatus.ACTIVE ||
      this.status === EventStatus.ACTIVE_SPECIFIC_DATE
    );
  }

  isDraft(): boolean {
    return (
      this.status === EventStatus.DRAFT || this.status === EventStatus.INACTIVE
    );
  }

  getLink(): string {
    return this.type === EventType.LANDINGPAGE
      ? `${env.website}/landing/${this.uri}`
      : `${env.website}/events/${this.uri}`;
  }

  getVotingLink(): string {
    return `${env.website}/c/${this.eventCampaign?.campaign?.uri}/voting/${this.uri}`;
  }
  getVotingLinkRelative(): string {
    return `/c/${this.eventCampaign?.campaign?.uri}/voting/${this.uri}`;
  }

  getDisplayEndDateForCalendar(): string {
    return this.endDate
      ? this.endDate.toISOString()
      : moment(this.startDate).add(2, 'hours').toISOString();
  }

  getDisplayEndDateForCalendarIcs(): string {
    return this.endDate
      ? moment(this.endDate).utc().format('YYYYMMDDTHHmmss') + 'Z'
      : moment(this.startDate).add(2, 'hours').utc().format('YYYYMMDDTHHmmss') +
          'Z';
  }

  getEndDateForCalendar(): Date {
    return this.endDate
      ? this.endDate
      : moment(this.startDate).add(2, 'hours').toDate();
  }

  getStartDateTzMoment(): Moment {
    return moment.tz(this.startDate, this.timeZone);
  }

  getEndDateTzMoment(): Moment | null {
    return this.endDate ? moment.tz(this.endDate, this.timeZone) : null;
  }

  getStartDate() {
    const startDateTzMoment = this.getStartDateTzMoment();
    return startDateTzMoment
      ? new Date(
          startDateTzMoment.year(),
          startDateTzMoment.month(),
          startDateTzMoment.date(),
        )
      : null;
  }

  getEndDate(): Date | null {
    const endDateTzMoment = this.getEndDateTzMoment();
    return endDateTzMoment
      ? new Date(
          endDateTzMoment.year(),
          endDateTzMoment.month(),
          endDateTzMoment.date(),
        )
      : null;
  }

  getStartTime(): string {
    const startDateTzMoment = this.getStartDateTzMoment();
    return startDateTzMoment ? startDateTzMoment.format('HH:mm') : '';
  }

  getEndTime(): string {
    const endDateTzMoment = this.getEndDateTzMoment();
    return endDateTzMoment ? endDateTzMoment.format('HH:mm') : '';
  }

  getTimezoneOption(): TimezoneOption | undefined {
    if (!this.timeZone || this.timeZone === '') {
      return undefined;
    }
    return new TimezoneOption({
      name: this.timeZone,
      offset: moment.tz(this.timeZone).utcOffset(),
    });
  }

  hasCampaignLogo(): boolean {
    return (
      this.eventCampaign?.status === EventCampaignStatus.APPROVED &&
      !!this.eventCampaign?.campaign?.logoUrl &&
      this.eventCampaign?.campaign?.logoUrl !== ''
    );
  }

  getActiveCoHosts(): EventHost[] | undefined {
    return this.hosts?.filter(
      (x) =>
        x.status === EventHostStatus.ACTIVE &&
        x.role === EventHostRoleEnum.CO_HOST,
    );
  }

  maxCapacityReached(): boolean {
    return (
      this.maxCapacity !== undefined &&
      this.maxCapacity !== 0 &&
      this.maxCapacity <= (this.getNumYesAttendees() ?? 0)
    );
  }

  hasOneButtonSignUp(): boolean {
    return (
      this.uri === 'a-park-gewinnspiel-2024' ||
      this.type === EventType.SPORTPAGE
    );
  }

  rsvpWithoutEmail(): boolean {
    return this.uri === 'a-park-gewinnspiel-2024';
  }

  getLandingPageEvents(): Event[] | undefined {
    return this.landingPageEvents
      ?.filter((landingPageEvent) => !!landingPageEvent.event)
      .map((landingPagEvent) => landingPagEvent.event!);
  }

  setDefaultRSVPOptions(): EventRSVPOption[] {
    const newRSVPOptions = [];
    if (
      !this.rsvpOptions?.find((option) => option.type === RSVPOptionType.YES)
    ) {
      newRSVPOptions.push(
        new EventRSVPOption({
          id: 0,
          eventId: this.id,
          type: RSVPOptionType.YES,
          name: 'APP.RSVP_OPTION.YES',
          emoji: '😀',
        }),
      );
    }
    if (
      !this.rsvpOptions?.find((option) => option.type === RSVPOptionType.NO)
    ) {
      newRSVPOptions.push(
        new EventRSVPOption({
          id: 0,
          eventId: this.id,
          type: RSVPOptionType.NO,
          name: 'APP.RSVP_OPTION.NO',
          emoji: '😢',
        }),
      );
    }
    if (
      !this.rsvpOptions?.find((option) => option.type === RSVPOptionType.MAYBE)
    ) {
      newRSVPOptions.push(
        new EventRSVPOption({
          id: 0,
          eventId: this.id,
          type: RSVPOptionType.MAYBE,
          name: 'APP.RSVP_OPTION.MAYBE',
          emoji: '🤔',
        }),
      );
    }

    return newRSVPOptions;
  }

  getEventCampaignStatus(): EventCampaignStatus | undefined {
    return this.eventCampaign?.status;
  }

  getEventCampaignCategory(): EventCampaignCategory | undefined {
    return this.eventCampaign?.category;
  }

  canRsvp(): boolean {
    if (!this.startDate) {
      return this.rsvpEnabled;
    }
    const rsvpDisableDate = this.rsvpDisableDate
      ? this.rsvpDisableDate
      : moment(this.startDate).add(2, 'hours').set({ minute: 0 });

    return this.rsvpEnabled && moment().isBefore(rsvpDisableDate);
  }

  getRsvpDisableDate(): Date | undefined {
    return this.rsvpDisableDate ? this.rsvpDisableDate : this.startDate;
  }

  getRsvpConfirmationTextByType(
    type: RSVPOptionType,
  ): EventRSVPConfirmationText | undefined {
    return this.rsvpConfirmationTexts?.find((text) => text.type === type);
  }

  isMainPictureVideo(): boolean {
    return !!this.mainPictureUrl?.includes('.mp4');
  }

  is12HourDateFormat(): boolean {
    return !!this.dateFormat && this.dateFormat === DateFormat.HOUR_12;
  }

  getAttendeeQuestionsSorted(): EventAttendeeQuestion[] | undefined {
    return this.attendeeQuestions?.sort((a, b) => a.order - b.order);
  }

  getAttendeeQuestionsSortedForTable(): EventAttendeeQuestion[] | undefined {
    return this.attendeeQuestions?.sort((a, b) => b.order - a.order);
  }

  private mapEventAttendees(attendees: EventAttendee[]): EventAttendee[] {
    return attendees.map((it) => new EventAttendee(it));
  }

  private mapEventRSVPOptions(
    rsvpOptions: EventRSVPOption[],
  ): EventRSVPOption[] {
    return rsvpOptions.map((it) => new EventRSVPOption(it));
  }

  private mapEventHosts(hosts: EventHost[]): EventHost[] {
    return hosts.map((it) => new EventHost(it));
  }

  private mapAttendeeQuestions(
    attendeeQuestions: EventAttendeeQuestion[],
  ): EventAttendeeQuestion[] {
    return attendeeQuestions.map((it) => new EventAttendeeQuestion(it));
  }

  private mapRsvpConfirmationTexts(
    rsvpConfirmationTexts: EventRSVPConfirmationText[],
  ): EventRSVPConfirmationText[] {
    return rsvpConfirmationTexts.map((it) => new EventRSVPConfirmationText(it));
  }

  private mapLandingPageEvents(events: LandingPageEvent[]): LandingPageEvent[] {
    return events.map((it) => new LandingPageEvent(it));
  }
}

export function createExampleEventForTemplatePreview(
  eventTemplate: EventTemplate,
) {
  return new Event({
    id: 0,

    type: eventTemplate.type,

    uuid: '',
    uri: '',

    categoryId: 0,

    eventTemplateId: eventTemplate.id,
    eventTemplate: eventTemplate,

    hostUserId: 0,
    hostUser: new User({
      id: 0,

      email: 'test@eventpage.ai',
      emailVerified: true,

      role: UserRole.CUSTOMER,

      name: 'Erika Mustermann',

      phone: '',
      phoneVerified: false,

      avatarUrl:
        'https://images.pexels.com/photos/762020/pexels-photo-762020.jpeg',
      gender: '',
      description: '',
      language: '',
      platform: '',
      isB2B: false,
    }),

    eventAddressId: 0,
    eventAddress: eventTemplate.eventAddress
      ? eventTemplate.eventAddress
      : new Address({
          street: 'Musterstraße',
          streetNumber: '15',
          postalCode: '12345',
          locality: 'Musterstadt',
          lat: 49.4415725,
          lng: 8.2703097,
        }),

    attendees: [
      new EventAttendee({
        id: 1,
        userId: 0,
        user: new User({
          id: 0,

          email: 'test@eventpage.ai',
          emailVerified: true,

          role: UserRole.CUSTOMER,

          name: 'John Citizen',

          phone: '',
          phoneVerified: false,

          avatarUrl:
            'https://images.unsplash.com/photo-1560250097-0b93528c311a?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cG9ydHJhaXQlMjBtYW58ZW58MHx8MHx8fDA%3D',
          gender: '',
          description: '',
          language: '',
          platform: '',
          isB2B: false,
        }),
        eventId: 0,
        status: EventAttendeeStatus.ATTENDING,
        rsvpType: RSVPOptionType.YES,
        rsvpDate: new Date(),
        numAttendees: 1,
        hasPaid: false,
      }),
      new EventAttendee({
        id: 1,
        userId: 0,
        user: new User({
          id: 0,

          email: 'test@eventpage.ai',
          emailVerified: true,

          role: UserRole.CUSTOMER,

          name: 'Max Schmidt',

          phone: '',
          phoneVerified: false,

          avatarUrl:
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0uUhhISOKkw5MpBnsCMWTiHZ9D8-bzGNsgw&usqp=CAU',
          gender: '',
          description: '',
          language: '',
          platform: '',
          isB2B: false,
        }),
        eventId: 0,
        status: EventAttendeeStatus.ATTENDING,
        rsvpType: RSVPOptionType.YES,
        rsvpDate: new Date(),
        numAttendees: 1,
        hasPaid: false,
      }),
      new EventAttendee({
        id: 1,
        userId: 0,
        user: new User({
          id: 0,

          email: 'test@eventpage.ai',
          emailVerified: true,

          role: UserRole.CUSTOMER,

          name: 'Marianna-sabine',

          phone: '',
          phoneVerified: false,

          avatarUrl:
            'https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmVtYWxlJTIwcG9ydHJhaXR8ZW58MHx8MHx8fDA%3D',
          gender: '',
          description: '',
          language: '',
          platform: '',
          isB2B: false,
        }),
        eventId: 0,
        status: EventAttendeeStatus.ATTENDING,
        rsvpType: RSVPOptionType.YES,
        rsvpDate: new Date(),
        numAttendees: 1,
        hasPaid: false,
      }),
      new EventAttendee({
        id: 1,
        userId: 0,
        user: new User({
          id: 0,

          email: 'test@eventpage.ai',
          emailVerified: true,

          role: UserRole.CUSTOMER,

          name: 'Lukas Müller',

          phone: '',
          phoneVerified: false,

          avatarUrl:
            'https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg?size=626&ext=jpg&ga=GA1.1.1700460183.1708041600&semt=ais',
          gender: '',
          description: '',
          language: '',
          platform: '',
          isB2B: false,
        }),
        eventId: 0,
        status: EventAttendeeStatus.ATTENDING,
        rsvpType: RSVPOptionType.YES,
        rsvpDate: new Date(),
        numAttendees: 1,
        hasPaid: false,
      }),
      new EventAttendee({
        id: 1,
        userId: 0,
        user: new User({
          id: 0,

          email: 'test@eventpage.ai',
          emailVerified: true,

          role: UserRole.CUSTOMER,

          name: 'Marie Dupont',

          phone: '',
          phoneVerified: false,

          avatarUrl:
            'https://img.freepik.com/free-photo/young-female-model-portrait_23-2149084889.jpg',
          gender: '',
          description: '',
          language: '',
          platform: '',
          isB2B: false,
        }),
        eventId: 0,
        status: EventAttendeeStatus.ATTENDING,
        rsvpType: RSVPOptionType.YES,
        rsvpDate: new Date(),
        numAttendees: 1,
        hasPaid: false,
      }),
      new EventAttendee({
        id: 1,
        userId: 0,
        user: new User({
          id: 0,

          email: 'test@eventpage.ai',
          emailVerified: true,

          role: UserRole.CUSTOMER,

          name: 'Alexander Wagner',

          phone: '',
          phoneVerified: false,

          avatarUrl:
            'https://t4.ftcdn.net/jpg/06/48/39/19/360_F_648391979_uMz6EwAlKNIJnK9r46UpTiM17nT8GuLl.jpg',
          gender: '',
          description: '',
          language: '',
          platform: '',
          isB2B: false,
        }),
        eventId: 0,
        status: EventAttendeeStatus.MAYBE_ATTENDING,
        rsvpType: RSVPOptionType.MAYBE,
        rsvpDate: new Date(),
        numAttendees: 1,
        hasPaid: false,
      }),
      new EventAttendee({
        id: 1,
        userId: 0,
        user: new User({
          id: 0,

          email: 'test@eventpage.ai',
          emailVerified: true,

          role: UserRole.CUSTOMER,

          name: 'Paul Hoffmann',

          phone: '',
          phoneVerified: false,

          avatarUrl:
            'https://t4.ftcdn.net/jpg/03/07/36/97/360_F_307369771_Mm1wbpgaNeaW36hLqivp86gnsx20LSWY.jpg',
          gender: '',
          description: '',
          language: '',
          platform: '',
          isB2B: false,
        }),
        eventId: 0,
        status: EventAttendeeStatus.MAYBE_ATTENDING,
        rsvpType: RSVPOptionType.MAYBE,
        rsvpDate: new Date(),
        numAttendees: 1,
        hasPaid: false,
      }),
    ],

    rsvpOptions: [
      {
        id: 0,
        eventId: 1,
        type: RSVPOptionType.YES,
        name: 'APP.RSVP_OPTION.YES',
        emoji: '😀',
      },
      {
        id: 0,
        eventId: 1,
        type: RSVPOptionType.NO,
        name: 'APP.RSVP_OPTION.NO',
        emoji: '😢',
      },
      {
        id: 0,
        eventId: 1,
        type: RSVPOptionType.MAYBE,
        name: 'APP.RSVP_OPTION.MAYBE',
        emoji: '🤔',
      },
    ],

    status: EventStatus.DRAFT,
    access: EventAccess.PUBLIC,

    name: eventTemplate.eventName,
    subHeader: eventTemplate.eventCta,
    description: eventTemplate.description,

    tags: '',

    frequency: EventFrequency.ONE_TIME,

    ticketId: 0,
    ticket: {
      id: 0,
      link: 'https://eventpage.ai',
      priceInCents: 3500,
    },

    startDate: eventTemplate.startDate || moment().add(2, 'hours').toDate(),
    endDate: eventTemplate.endDate,
    timeZone: eventTemplate.timeZone || moment.tz.guess(),

    plusOnesAdditionalData: false,
    showPlusOnesFirstName: false,
    showPlusOnesLastName: false,
    showPlusOnesEmail: false,
    showPlusOnesPhone: false,

    showGuestList: true,
    showGuestCount: true,
    showFeedTimestamps: false,
    canAttendeesAddPhotos: true,
    canAddPhotosViaHashtag: false,
    canAttendeeInviteMutuals: false,

    rsvpEnabled: true,
    requireAttendeeApproval: false,
    requireGroupRsvp: false,

    sendEmailNotifications: false,
    sendSmsNotifications: false,
    sendWhatsAppNotifications: false,

    isPreviewEvent: true,
  });
}

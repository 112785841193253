import { ClassData } from '@models/class-data';

export class EventTicket {
  id: number;

  link: string;

  priceInCents: number;

  constructor(json: ClassData<EventTicket>) {
    this.id = +json.id;

    this.link = json.link;

    this.priceInCents = +json.priceInCents;
  }
}

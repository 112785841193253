import { ClassData } from '@models/class-data';
import { CampaignStatus } from './campaign-status.enum';
import { EventCategory } from '@models/event-categories/event-category.model';
import { TargetGroup } from '@models/shared/target-group.model';

export class Campaign {
  id: number;
  createdAt?: Date;

  status: CampaignStatus;

  name: string;
  uri: string;
  logoUrl: string;

  enteringScreenTitle: string;
  welcomeScreenHtml: string;

  preselectedEventCategoryId?: number;
  preselectedEventCategory?: EventCategory;

  preselectedTargetGroupId?: number;
  preselectedTargetGroup?: TargetGroup;

  allCampaignUsersListId?: number;
  campaignAdsUsersListId?: number;

  accountCreationForVoting: boolean;

  constructor(json: ClassData<Campaign>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.status = json.status;

    this.name = json.name;
    this.uri = json.uri;
    this.logoUrl = json.logoUrl;

    this.enteringScreenTitle = json.enteringScreenTitle;
    this.welcomeScreenHtml = json.welcomeScreenHtml;

    if (json.preselectedEventCategoryId !== undefined) {
      this.preselectedEventCategoryId = +json.preselectedEventCategoryId;
    }
    if (json.preselectedEventCategory) {
      this.preselectedEventCategory = new EventCategory(
        json.preselectedEventCategory,
      );
    }

    if (json.preselectedTargetGroupId !== undefined) {
      this.preselectedTargetGroupId = +json.preselectedTargetGroupId;
    }
    if (json.preselectedTargetGroup) {
      this.preselectedTargetGroup = json.preselectedTargetGroup;
    }

    if (json.allCampaignUsersListId !== undefined) {
      this.allCampaignUsersListId = +json.allCampaignUsersListId;
    }
    if (json.campaignAdsUsersListId !== undefined) {
      this.campaignAdsUsersListId = +json.campaignAdsUsersListId;
    }

    this.accountCreationForVoting = json.accountCreationForVoting;
  }

  getEventParentCategoryId(): number | undefined {
    if (!this.preselectedEventCategory) {
      return undefined;
    }

    return this.preselectedEventCategory.parentCategoryId
      ? this.preselectedEventCategory.parentCategoryId
      : this.preselectedEventCategory.id;
  }

  getEventSubCategoryId(): number | undefined {
    if (!this.preselectedEventCategory) {
      return undefined;
    }

    return this.preselectedEventCategory.parentCategoryId
      ? this.preselectedEventCategory.id
      : undefined;
  }
}

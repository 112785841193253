import { Injectable } from '@angular/core';
import { CampaignEventVoteRequest } from '@models/events/dto/campaign-event-vote.request';
import { Observable } from 'rxjs';
import { environment as env } from '@environments/environment';
import { ApiService } from '@services/api.service';
import { map, tap } from 'rxjs/operators';
import { EventCreationRequest } from '@models/events/dto/event-creation.request';
import { Event } from '@models/events/event.model';
import { EventSuggestedData } from '@models/events/dto/event-suggested-data.response';
import { EventRSVPRequest } from '@models/events/dto/event-rsvp.request';
import { EventHost } from '@models/events/event-host.model';
import { EmailRequest } from '@models/shared/email.request';
import { EventAttendee } from '@models/event-attendance/event-attendee.model';
import { UpdateEventAttendeeRsvpOptionRequest } from '@models/events/dto/update-event-attendee-rsvp-option.request';
import { EventUpdateRequest } from '@models/events/dto/event-update.request';
import { EventAIMoodBoardRequest } from '@models/events/dto/event-ai-mood-board.request';
import { EventAIMoodBoardResponse } from '@models/events/dto/event-ai-mood-board.response';
import { User } from '@models/users/user.model';
import { PageListingResponse } from '@models/api/page-listing-response.model';
import { EventCampaignStatus } from '@models/campaigns/event-campaign-status.enum';
import { EventInviteRequest } from '@models/events/dto/event-invite.request';
import { EventCohostAcceptOrRejectInvitationRequest } from '@models/events/dto/event-cohost-accept-or-reject-invitation.request';
import { VerifyAccessCodeRequest } from '@models/events/dto/verify-access-code.request';
import { CampaignEventRankingsResponse } from '@models/campaigns/dto/event-campaign-rating.response';
import {EventCohostAcceptInvitationRequest} from "@models/events/dto/event-cohost-accept-invitation.request";
import { BlobWithFileName } from '@models/api/blob-with-filename.model';
import { saveAs } from 'file-saver';
import { EventAttendeesExportRequest } from '@models/events/dto/event-attendees-export.request';
import { SaveAttendeeRequest } from '@models/events/dto/save-attendee.request';
import { VerifyNoAccountRSVPRequest } from '@models/events/dto/verify-no-account-rsvp.request';

@Injectable()
export class EventService {
  constructor(private api: ApiService) {}

  create(object: EventCreationRequest): Observable<Event> {
    return this.api
      .post(`${env.api}/events/create`, object)
      .pipe(map((it) => new Event(it)));
  }

  get(id: number): Observable<Event> {
    return this.api
      .get(`${env.api}/events/${id}`)
      .pipe(map((it) => new Event(it)));
  }

  getByUri(uri: string, lsParams?: Map<string, string>): Observable<Event> {
    return this.api
      .get(`${env.api}/events/uri/${uri}`, lsParams)
      .pipe(map((it) => new Event(it)));
  }

  setLinkBioVisibility(id: number, hidden: boolean) {
    return this.api.post(`${env.api}/events/${id}/set-link-bio-visibility`, {
      hidden: hidden,
    });
  }

  setLinkBioHighlight(id: number, highlighted: boolean) {
    return this.api.post(`${env.api}/events/${id}/set-link-bio-highlight`, {
      highlighted: highlighted,
    });
  }

  getCampaignEventsPageNumberOfEvent(lsParams?: Map<string, string>) {
    return this.api.get(
      `${env.api}/events/campaign-events/page-number`,
      lsParams,
    );
  }
  getEvents(filters: Map<string, any>): Observable<Event[]> {
    return this.api.get(`${env.api}/events`, filters).pipe(map(this.mapEvents));
  }

  private mapEvents(res: Event[]) {
    return res?.map((it) => new Event(it));
  }

  getEventsPaged(
    filters: Map<string, string>,
    pageNumber: number = 1,
    pageSize: number = 10,
  ): Observable<PageListingResponse<Event>> {
    return this.api
      .getPaged(`${env.api}/events/paged`, pageNumber, pageSize, filters)
      .pipe(map((it) => this.mapPaginatedEvents(it)));
  }

  getCampaignEventsPaged(
    filters: Map<string, string>,
    pageNumber: number = 1,
    pageSize: number = 10,
  ): Observable<PageListingResponse<Event>> {
    return this.api
      .getPaged(
        `${env.api}/events/campaign-events`,
        pageNumber,
        pageSize,
        filters,
      )
      .pipe(map((it) => this.mapPaginatedEvents(it)));
  }

  voteForEventCampaign(req?: CampaignEventVoteRequest): Observable<any> {
    return this.api.post(`${env.api}/events/campaign-events/vote`, req);
  }

  getCampaignRankings(
    req?: CampaignEventVoteRequest,
  ): Observable<CampaignEventRankingsResponse> {
    return this.api.post(`${env.api}/events/campaign-events/ranking`, req);
  }
  getWinnerCampaignRankings(
    req?: CampaignEventVoteRequest,
  ): Observable<CampaignEventRankingsResponse[]> {
    return this.api.post(
      `${env.api}/events/campaign-events/winning-rankings`,
      req,
    );
  }

  private mapPaginatedEvents(res: PageListingResponse<Event>) {
    return {
      total: res.total,
      records: res.records ? res.records.map((it) => new Event(it)) : [],
    };
  }

  getSuggestedData(
    prompt: string,
    campaignUri?: string,
  ): Observable<EventSuggestedData> {
    const params = new Map<string, string>();
    params.set('prompt', prompt);
    if (campaignUri) {
      params.set('campaignUri', campaignUri);
    }

    const userLang = localStorage.getItem('userLang');
    if (userLang) {
      params.set('userLanguage', userLang);
    }

    return this.api.get(`${env.api}/events/suggested-data`, params);
  }

  saveRSVP(eventId?: number, req?: EventRSVPRequest): Observable<User> {
    return this.api
      .post(`${env.api}/events/${eventId}/rsvp`, req)
      .pipe(map((it) => new User(it)));
  }

  verifyNoAccountRSVP(
    eventId: number,
    req: VerifyNoAccountRSVPRequest,
  ): Observable<void> {
    return this.api.post(
      `${env.api}/events/${eventId}/verify-no-account-rsvp`,
      req,
    );
  }

  exportEventAttendeesCsv(
    eventId: number,
    req: EventAttendeesExportRequest,
    fileName?: string,
  ): Observable<any> {
    return this.api
      .postBlobWithFileName(
        `${env.api}/events/${eventId}/export-attendees-csv`,
        req,
      )
      .pipe(
        tap((blobWithFileName: BlobWithFileName) => {
          saveAs(
            blobWithFileName.blob,
            fileName && fileName !== '' ? fileName : blobWithFileName.fileName,
          );
        }),
      );
  }

  addHost(eventId: number, req: EmailRequest): Observable<EventHost> {
    return this.api
      .post(`${env.api}/events/${eventId}/hosts`, req)
      .pipe(map((it) => new EventHost(it)));
  }

  removeHost(eventId: number, hostId: number): Observable<void> {
    return this.api.delete(`${env.api}/events/${eventId}/hosts/${hostId}`);
  }

  acceptOrRejectCoHostInvite(
    uri: string,
    req: EventCohostAcceptOrRejectInvitationRequest,
  ): Observable<void> {
    return this.api.post(
      `${env.api}/events/${uri}/co-host/accept-or-reject`,
      req,
    );
  }

  updateAttendeeRSVPOption(
    eventId?: number,
    req?: UpdateEventAttendeeRsvpOptionRequest,
  ): Observable<EventAttendee[]> {
    return this.api
      .put(`${env.api}/events/update-attendee-rsvp/${eventId}`, req)
      .pipe(map(this.mapEventsAttendees));
  }

  saveAttendee(
    eventId: number,
    req: SaveAttendeeRequest,
  ): Observable<EventAttendee> {
    return this.api
      .post(`${env.api}/events/${eventId}/attendee`, req)
      .pipe(map((it) => new EventAttendee(it)));
  }

  deleteAttendee(eventId: number, attendeeId: number): Observable<void> {
    return this.api.delete(`${env.api}/events/${eventId}/rsvp/${attendeeId}`);
  }

  private mapEventsAttendees(res: EventAttendee[]) {
    return res?.map((it) => new EventAttendee(it));
  }

  updateEvent(eventId?: number, req?: EventUpdateRequest): Observable<Event> {
    return this.api
      .put(`${env.api}/events/update/${eventId}`, req)
      .pipe(map((it) => new Event(it)));
  }

  createAIMoodBoard(
    req: EventAIMoodBoardRequest,
  ): Observable<EventAIMoodBoardResponse> {
    const userLang = localStorage.getItem('userLang');
    if (userLang) {
      req.userLanguage = userLang;
    }
    return this.api.post(`${env.api}/events/ai-mood-board`, req);
  }

  createUserAtPublish(id?: string, req?: EventUpdateRequest): Observable<User> {
    return this.api
      .put(`${env.api}/events/${id}/create-new-user-at-publish`, req)
      .pipe(map((it) => new User(it)));
  }

  setEventCampaignStatus(
    eventId: number,
    status: EventCampaignStatus,
  ): Observable<EventAIMoodBoardResponse> {
    return this.api.post(`${env.api}/events/${eventId}/set-campaign-status`, {
      eventId,
      status,
    });
  }

  delete(id: number): Observable<void> {
    return this.api.delete(`${env.api}/events/delete/${id}`);
  }

  duplicate(id: number) {
    return this.api.get(`${env.api}/events/${id}/duplicate`);
  }

  inviteGuests(id: number, req: EventInviteRequest): Observable<void> {
    return this.api.post(`${env.api}/events/${id}/invite-guests`, req);
  }

  getEventAttendeeByUuid(
    id: number,
    attendeeUuid: string,
  ): Observable<EventAttendee> {
    return this.api
      .get(`${env.api}/events/${id}/attendee/${attendeeUuid}`)
      .pipe(map((it) => new EventAttendee(it)));
  }

  verifyAccessCode(req: VerifyAccessCodeRequest): Observable<void> {
    return this.api.post(`${env.api}/events/verify-access-code`, req);
  }
}
